<template>
  <div>
    <el-form
      :model="poc"
      label-position="left"
      label-width="100px"
      :rules="rules"
      ref="pocDetail"
    >
      <el-row type="flex" justify="space-between">
        <el-col>
          <el-form-item label="Name" prop="name">
            <el-input size="mini" clearable v-model="poc.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :offset="1">
          <el-form-item label="Version" prop="version">
            <el-input size="mini" clearable v-model="poc.version"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col>
          <el-form-item label="URL" prop="url">
            <el-input v-model="poc.url" clearable size="mini">
              <!-- <template slot="prepend">Https://</template> -->
              <!-- <template slot="append">.mpstages.cn</template> -->
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :offset="1">
          <el-form-item label="Status" prop="status">
            <el-select
              style="width: 100%;"
              size="mini"
              placeholder="Status"
              clearable
              v-model="poc.status"
            >
              <el-option
                v-for="item in statuses"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col>
          <el-form-item label="Valid From">
            <el-date-picker
              style="width: 100%;"
              v-model="poc.validFrom"
              type="date"
              placeholder="From"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :offset="1">
          <el-form-item label="Valid To">
            <el-date-picker
              v-model="poc.validTo"
              type="date"
              placeholder="To"
              style="width: 100%;"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col>
          <el-form-item label="Customer">
            <el-select
              size="mini"
              style="width: 100%;"
              clearable
              placeholder="Customer"
              v-model="poc.customer"
            >
              <el-option
                v-for="item in customers"
                :key="item._id"
                :value="item.name"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :offset="1">
          <el-form-item label="Owner">
            <el-select
              style="width: 100%;"
              size="mini"
              placeholder="POC Owner"
              clearable
              v-model="poc.assignee"
            >
              <el-option
                v-for="item in assignees"
                :key="item.username"
                :value="item.username"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <div style="width: 100%; height: 30px; line-height: 30px; float:left;">
          <el-button
            type="primary"
            style="float: right;"
            size="mini"
            @click="handleConfirm"
            >Confirm</el-button
          >
          <el-button
            style="float:right; margin-right: 10px;"
            size="mini"
            @click="handleCancel"
            >Cancel</el-button
          >
        </div>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import config from "../utils/config";
import http from "../utils/http";
export default {
  name: "PocDetail",
  props: {
    editPoc: {},
  },
  data() {
    return {
      rules: {
        name: [
          { required: true, message: "Name is required.", trigger: "blur" },
        ],
        version: [
          { required: true, message: "Version is required.", trigger: "blur" },
        ],
        url: [{ required: true, message: "URL is required.", trigger: "blur" }],
        status: [
          { required: true, message: "Status is required.", trigger: "blur" },
        ],
      },
      statuses: config.pocStatuses,
      customers: [],
      assignees: [],
      poc: {
        name: "",
        version: "",
        url: "",
        status: "",
        validFrom: "",
        validTo: "",
        assignee: "",
        customer: "",
      },
    };
  },
  mounted() {
    this.getCustomer();
    this.getInternalUsers();
    if (JSON.stringify(this.editPoc) != "{}") {
      var o = this.editPoc;
      this.poc._id = o._id;
      this.poc.name = o.name;
      this.poc.version = o.version;
      this.poc.url = o.url;
      this.poc.status = o.status;
      this.poc.validFrom = o.validFrom;
      this.poc.validTo = o.validTo;
      this.poc.assignee = o.assignee;
      this.poc.customer = o.customer;
    }
  },
  methods: {
    updatePoc() {
      http.post("/poc/update", this.poc).then((res) => {
        if (res.data.status == 1) {
          this.$message({ type: "success", message: "Updated successfully!" });
          this.$emit("confirm");
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    handleConfirm() {
      this.$refs.pocDetail.validate((valid) => {
        if (valid) {
          if (this.poc._id != undefined) {
            this.updatePoc();
            return;
          }
          http.post("/poc/create", this.poc).then((res) => {
            if (res.data.status == 1) {
              this.$message({
                type: "success",
                message: "The POC was created successfully!",
              });
              this.$emit("confirm");
            } else {
              this.$message.error(res.data.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.$emit("cancel");
    },
    getCustomer() {
      http.get("/customer/getCustomer").then((res) => {
        if (res.data.status === 1) {
          this.customers.splice(0, this.customers.length);
          this.customers = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getInternalUsers() {
      http.get("/user/internal").then((res) => {
        if (res.data.status === 1) {
          this.assignees = res.data.data;
        } else {
          this.$message.error("Error happened!");
        }
      });
    },
  },
};
</script>
<style scoped></style>
