<template>
  <div>
    <div class="header">
      <el-button size="mini" type="primary" @click="handleCreateClick"
        >Create</el-button
      >
      <el-input
        size="mini"
        suffix-icon="search"
        placeholder="Search..."
        v-model="kw"
        clearable
        style="width: 200px; margin-left: 20px;"
      ></el-input>
      <el-select
        v-model="status"
        clearable
        size="mini"
        style="width: 200px; margin-left: 20px;"
        placeholder="Status"
        multiple
        collapse-tags
      >
        <el-option
          v-for="item in statuses"
          :key="item"
          :value="item"
          :label="item"
        ></el-option>
      </el-select>
      <el-select
        v-model="assignee"
        clearable
        multiple
        collapse-tags
        size="mini"
        style="margin-left: 20px;"
        placeholder="Owner"
      >
        <el-option
          v-for="item in assignees"
          :key="item.username"
          :value="item.username"
          :label="item.name"
        ></el-option>
      </el-select>
      <el-button
        size="mini"
        style="margin-left: 20px;"
        type="primary"
        icon="el-icon-lightning-full"
        @click="getPoc"
      ></el-button>
      <el-tag size="medium" type="info" style="margin-left: 20px;"
        >In total: {{ selectedPocs.length }}</el-tag
      >
    </div>
    <el-table
      :data="selectedPocs"
      :height="tableHeight"
      stripe
      border
      size="mini"
      style="margin-top: 10px; float:left;"
    >
      <el-table-column
        prop="name"
        label="Name"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="version" label="Version"></el-table-column>
      <el-table-column label="URL" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-link :href="scope.row.url" target="_blank">{{
            scope.row.url
          }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Status" sortable>
        <template slot-scope="scope">
          <el-tag
            size="small"
            type="success"
            v-if="scope.row.status == 'running'"
            >{{ scope.row.status }}</el-tag
          >
          <el-tag
            type="danger"
            size="small"
            v-else-if="scope.row.status == 'stopped'"
            >{{ scope.row.status }}</el-tag
          >
          <el-tag
            type="info"
            size="small"
            v-else-if="scope.row.status == 'terminated'"
            >{{ scope.row.status }}</el-tag
          >
          <el-tag size="small" v-else>{{ scope.row.status }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Validity" align="center">
        <el-table-column
          prop="validFrom"
          label="Valid From"
          show-overflow-tooltip
          :formatter="dateFormatterFrom"
        >
        </el-table-column>
        <el-table-column
          :formatter="dateFormatterTo"
          prop="validTo"
          label="Valid To"
          show-overflow-tooltip
        ></el-table-column>
      </el-table-column>
      <el-table-column
        prop="creationTime"
        show-overflow-tooltip
        sortable
        label="Created On"
        :formatter="dateFormatterCreate"
      >
        <!-- <template slot-scope="scope">
          {{
            new Date(scope.row.creationTime).toLocaleString("chinese", {
              hour12: false,
            })
          }}
        </template> -->
      </el-table-column>
      <el-table-column
        prop="assignee"
        label="Owner"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="customer"
        label="Customer"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="Action" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-edit"
            size="mini"
            circle
            plain
            @click="handleEdit(scope)"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            circle
            plain
            @click="handleDelete(scope)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="title" :visible.sync="showDialog">
      <poc-detail
        v-if="showDialog"
        @confirm="handleConfirm"
        @cancel="handleCancel"
        :editPoc="poc"
      ></poc-detail>
    </el-dialog>
  </div>
</template>
<script>
import PocDetail from "../components/PocDetail";
import http from "../utils/http";
import config from "../utils/config";
export default {
  name: "POC",
  components: {
    PocDetail,
  },
  data() {
    return {
      kw: "",
      status: [],
      statuses: config.pocStatuses,
      tableHeight: window.innerHeight - 180,
      poc: {},
      showDialog: false,
      title: "",
      assignee: [],
      assignees: [],
      pocs: [],
    };
  },
  computed: {
    selectedPocs() {
      var kw = this.kw.toLowerCase();
      return this.pocs.filter((value) => {
        return (
          (this.kw == ""
            ? true
            : value.name.toLowerCase().indexOf(kw) > -1 ||
              value.url.toLowerCase().indexOf(kw) > -1) &&
          (this.status.length > 0
            ? this.status.indexOf(value.status) > -1
            : true) &&
          (this.assignee.length > 0
            ? this.assignee.indexOf(value.assignee) > -1
            : true)
        );
      });
    },
  },
  mounted() {
    this.getPoc();
    this.getInternalUsers();
  },
  methods: {
    getInternalUsers() {
      http.get("/user/internal").then((res) => {
        if (res.data.status === 1) {
          this.assignees = res.data.data;
        } else {
          this.$message.error("Error happened!");
        }
      });
    },
    handleDelete(scope) {
      http.post("/poc/delete", { _id: scope.row._id }).then((res) => {
        if (res.data.status == 1) {
          this.$message({
            type: "success",
            message: "POC was deleted successfully",
          });
          this.pocs.splice(this.pocs.indexOf(scope.row), 1);
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    handleEdit(scope) {
      this.poc = scope.row;
      this.title = "Edit POC";
      this.showDialog = true;
    },
    dateFormatterCreate(row) {
      var y, m, d;
      if (row.creationTime != "" && row.creationTime != null) {
        var f = new Date(row.creationTime);
        y = f.getFullYear();
        m = f.getMonth() + 1;
        d = f.getDate();
        return (
          y +
          "-" +
          (m < 10 ? "0" + m : m) +
          "-" +
          (d < 10 ? "0" + d : d) +
          ", " +
          f.getHours() +
          ":" +
          f.getMinutes() +
          ":" +
          f.getSeconds()
        );
      } else {
        return "N.A.";
      }
    },
    dateFormatterTo(row) {
      var y, m, d;
      if (row.validTo != "" && row.validTo != null) {
        var f = new Date(row.validTo);
        y = f.getFullYear();
        m = f.getMonth() + 1;
        d = f.getDate();
        return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
      } else {
        return "N.A.";
      }
    },
    dateFormatterFrom(row) {
      var y, m, d;
      if (row.validFrom != "" && row.validFrom != null) {
        var f = new Date(row.validFrom);
        y = f.getFullYear();
        m = f.getMonth() + 1;
        d = f.getDate();
        return y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
      } else {
        return "N.A.";
      }
    },
    getPoc() {
      http.get("/poc").then((res) => {
        if (res.data.status == 1) {
          this.pocs.splice(0, this.pocs.length);
          this.pocs = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    handleConfirm() {
      this.showDialog = false;
      this.getPoc();
    },
    handleCancel() {
      this.showDialog = false;
    },
    handleCreateClick() {
      this.poc = {};
      this.title = "Create POC";
      this.showDialog = true;
    },
  },
};
</script>
<style scoped>
.header {
  width: 1000px;
  height: 40px;
  line-height: 40px;
  text-align: left;
  float: left;
  /* margin-top: -10px; */
}
</style>
